<script setup lang="ts">
import { useDropZone } from "@vueuse/core";

const dropZoneRef = ref<HTMLDivElement>();

const { isOverDropZone } = useDropZone(dropZoneRef, onDrop);

const props = withDefaults(
  defineProps<{
    id: string;
    placeholder?: string;
    modelValue: Array<File>;
    valid?: boolean;
    error?: string;
    type?: string;
  }>(),
  {
    placeholder: "",
    valid: true,
    error: "",
    type: "text",
  }
);

const { modelValue: files } = toRefs(props);
const emit = defineEmits<{
  (e: "update:modelValue", value: Array<File>): void;
}>();

const updateValue = (event: any) => {
  const newFiles: File[] = new Array<File>();
  // 2. use for loop to transfer the data from fileList to files;
  const length = event.target.files.length;

  let i = 0;
  do {
    newFiles[i] = event.target.files.item(i);
  } while (i++ < length - 1);

  let filesUpdate = [...files.value];
  if (newFiles !== null && filesUpdate !== null) {
    filesUpdate = filesUpdate.concat(newFiles);
  } else {
    filesUpdate = newFiles;
  }

  emit("update:modelValue", filesUpdate);
};

function onDrop(newFiles: File[] | null) {
  let filesUpdate = [...files.value];
  if (newFiles !== null && filesUpdate !== null) {
    filesUpdate = filesUpdate.concat(newFiles);
  } else if (newFiles !== null) {
    filesUpdate = newFiles;
  }
  emit("update:modelValue", filesUpdate);
}

function handleClickDeleteFile(index: number) {
  if (files.value) {
    const filesUpdate = [...files.value];
    filesUpdate.splice(index, 1);
    emit("update:modelValue", filesUpdate);
  }
}
</script>

<template>
  <div :id="`${id}-zone`" ref="dropZoneRef">
    <div
      class="cursor-pointer rounded border-2 border-dashed p-6"
      :class="[
        isOverDropZone ? 'text-maas-typography-text-dark' : 'text-maas-typography-text-grey-medium',
        isOverDropZone ? 'border-maas-border-primary' : 'border-maas-border-grey-normal',
        isOverDropZone ? 'bg-maas-background-default' : 'bg-maas-background-grey-medium',
      ]"
    >
      <div class="dropzone flex flex-col items-center justify-center">
        <input v-bind="$attrs" :id="id" type="file" class="hidden" :name="id" multiple @input="updateValue" />
        <span class="my-2 text-6xl font-extralight leading-4">+</span>
        <p class="text-small mt-2 text-center">Datei auswählen oder hier ablegen.</p>
      </div>
    </div>

    <div v-if="!props.valid" class="mb-2 mt-4 text-red-700">{{ props.error }}</div>

    <div v-if="files && files.length > 0" class="mt-4">
      <h5 class="text-small font-bold">{{ files.length }} Datei{{ files.length > 1 ? "en" : "" }} hinzugefügt</h5>
      <div v-for="(file, index) in files" :key="file.name" class="text-small flex items-baseline gap-2">
        <span class="delete-file cursor-pointer" @click.prevent="handleClickDeleteFile(index)">
          <img src="@/assets/image/icon-delete.svg" />
        </span>
        <span>
          <span class="mr-1">
            {{ file.name }}
          </span>
          <span class="text-maas-typography-text-grey-medium whitespace-nowrap">
            ({{ (file.size / 1000000).toFixed(2) }} MB)</span
          >
        </span>
      </div>
    </div>
  </div>
</template>
